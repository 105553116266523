@import "../../assets/stylesheets/modules/variable";
@import "../../assets/stylesheets/modules/mixin";

.main-fix-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #f0f2f4;
}
.cco__lbl-logo {
  width: 250px;
}

.cco__login-form-wrapper {
  margin-top: 1.5rem;
}

//landing page main wrapper
.login-main-wrapper {
  width: 400px;
  background: $white;
  padding: 32px;
  margin: 2rem auto;
  overflow: hidden;
  position: relative;
  border: 1px solid $boarder-line;
  border-radius: 20px;

  .welcome-body-wrp {
    //left content
    .login-b-left-wrp {
      width: 100%;
      min-height: 400px;
      //Header
      .welcome-body-header {
        width: 100%;
        //@include itemCenter();

        .login-body-content {
          margin-top: $space24;
          width: 100%;

          .account-card-summary-wrapper {
            &:nth-child(1) {
              margin-left: 0;
              animation-delay: 0.6s;
            }

            &:nth-child(2) {
              animation-delay: 0.7s;
            }

            &:nth-child(3) {
              animation-delay: 0.8s;
            }
          }
        }

        .lbl-img-wrp {
          animation-delay: 0.1s;

          .lbl-logo {
            width: 120px;
          }
        }

        .nic-image-wrapper {
          .valid-nic {
            width: 140px;
          }
        }

        .social-media-wrapper {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            height: 20px;
            width: 50px;
            left: 42%;
            top: 20px;
            z-index: 0;
            background: var(--white);
          }

          &:before {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            left: 0;
            top: 25px;
            z-index: 0;
            background: var(--boarder-form-3);
          }

          .title {
            position: relative;
            @include itemCenter();
            @include font-bold();
            font-size: 12px;
            margin-top: 16px;
            margin-bottom: 16px;
            z-index: 1000;
          }

          .action-wrapper {
            margin-bottom: 12px;

            .social-login-wrapper {
              &:nth-child(1) {
                animation-delay: 0.5s;
              }

              &:nth-child(2) {
                animation-delay: 0.6s;
              }

              &:nth-child(3) {
                animation-delay: 0.6s;
              }
            }

            .social-login-wrapper {
              margin-left: 0.5rem;

              &:nth-child(1) {
                margin-left: 0;
              }
            }
          }
        }
      }

      //Footer action
      .login-footer-action-wrapper {
        button {
          margin-left: 0;

          &:nth-child(1) {
            margin-left: 0;
            //width: 250px;
          }
        }
      }
    }
  }
}

.profile-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 400px;
  margin: 20px auto;
  background: $white;
  border: 1px solid $boarder-line;
  padding: 12px 8px;
  border-radius: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  z-index: 100;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    .drop-down-menu {
      background: $primary-color;
      color: $font-second-color;
    }
  }

  .profile-img {
    width: 36px;
    height: 36px;
    background: $gray-200;
    border-radius: 100px;
    margin-right: 0.5rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .profile-details {
    font-family: $gotham400;
    font-size: 12px;
    margin-right: 0.5rem;

    .name {
      font-family: $gotham500;
      color: $font-primary-color;
    }

    .type {
      font-family: $gotham400;
      font-size: 11px;
      color: $font-second-color;
    }
  }
  .user-logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 8px;
    font-size: 11px;
    @include font-medium();
    border: 1px solid $boarder-form-3;
    border-radius: 12px;
    padding: 12px 24px;
    color: $primary-color;
    cursor: pointer;
    outline: none;
    &:hover {
      background: $primary-color-opacity;
    }
  }
}
.open-quick-account-wrapper {
  background: $primary-color-opacity;
  border-radius: $border-radius15;
  padding: $space24;
  margin-top: 40px;
  cursor: pointer;
  border: 1px solid transparent;

  .oq-a-icon-wrapper {
    border: 1px solid $child-color-1;
    color: $child-color-1;
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }

  &:hover {
    color: $white;
    border: 1px solid transparent;

    .oq-a-icon-wrapper {
      border: 1px solid transparent;
      background: $child-color-1;
      color: $white;
    }
  }

  .oq-a-left-wrp {
    width: 100%;

    .oq-a-title {
      @include font-bold();
      color: $font-primary-color;
      font-size: $font-size-md;
    }

    .oq-a-sub-title {
      @include font-regular();
      color: $font-secondary-color;
      font-size: $font-size-sm;
    }
  }
}

.wave-wrapper {
  position: absolute;
  bottom: -50px;
  left: -100px;
  z-index: 0;

  .wave-footer {
    width: 500px;
  }
}

.welcome-footer-wave-wrapper {
  position: absolute;
  bottom: -30px;
  left: 0px;
  z-index: 0;

  .wave-footer {
    width: 400px;
  }
}

.m-reg-content {
  margin-top: 0.5rem;
  @include font-regular();
  font-size: $font-size-md;
  color: $font-secondary-color;

  b {
    color: $secondary-color;
  }
}

.m-reg-form-inner-wrapper {
  margin-top: 16px;
}

.verification-message {
  margin-top: 2rem;
  @include font-regular();
  font-size: $font-size-md;
  color: $font-secondary-color;
}

.recent-count-down {
  margin-top: 1rem;
  margin-bottom: 2rem;
  @include font-bold();
  font-size: $font-size-md;
  color: $font-primary-color;
}

.verification-code-wrapper {
  margin-top: 1rem;

  .txt-verification-code {
    background: transparent;
    border-width: 0 0 2px 0;
    border-radius: 0;
    text-align: center;
    font-size: 24px;
    border-bottom-color: $boarder-form-2;

    &:hover,
    &:focus {
      background: transparent !important;
    }
  }

  input {
    margin-left: 0.5rem;
  }

  &:nth-child(1) {
    margin-left: 0;
  }
}

.join-artworkTop {
  position: absolute;
  top: 0;
  z-index: 102;
  left: 50%;
  right: 50%;
}
.filter-bg-1 {
  position: absolute;
  top: 0;
  right: 10%;
  margin-left: 103px;
  margin-top: -100px;
  z-index: 0;
}
.filter-bg-2 {
  position: absolute;
  left: 10%;
  bottom: 0;
  z-index: 0;
}
