/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2021 */

@font-face {
  font-family: 'ntbCamphorLight';
  src: url('CamphorW01Light.ttf') format('ttf'),
  url('CamphorW01Light.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ntbCamphorRegular';
  src: url('Camphor-W01-Regular.ttf');
}

@font-face {
  font-family: 'ntbCamphorMedium';
  src: url('Camphor-W01-Medium.ttf') format('ttf'),
  url('Camphor-W01-Medium.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ntbCamphorBold';
  src: url('CamphorW01Bold.ttf');
}


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2021 */

@font-face {
  font-family: 'gothamblack';
  src: url('gotham-black-webfont.woff') format('woff2'),
  url('gotham-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'gothamlight';
  src: url('gotham-light-webfont.woff') format('woff2'),
  url('gotham-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothambold';
  src: url('gotham-bold-webfont.woff') format('woff2'),
  url('gotham-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gothambook';
  src: url('gothambook-webfont.woff') format('woff2'),
  url('gothambook-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'gothammedium';
  src: url('gothammedium-webfont.woff') format('woff2'),
  url('gothammedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}





