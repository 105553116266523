@import "../../assets/stylesheets/modules/variable";
@import "../../assets/stylesheets/modules/mixin";

/* Portrait tablets and small desktops */
.mobile-wrapper {
  display: none;
}

@media (min-width: 768px) and (max-width: 991px) {}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {}

/* Portrait phones and smaller */
@media (max-width: 480px) {}

@media (min-width: 768px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1600px) {}


.ant-select:not(.ant-select-disabled):hover .ant-select-selector {}

.ant-select {}

