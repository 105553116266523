$bg-light: linear-gradient(179.82deg, #e9f7ff 0.15%, #fff0f9 100.96%);
$timeline-gradient: linear-gradient(90deg, #ea299b 0%, #0081cd 100%);

/* home-bg */
$bg-light-console: linear-gradient(
  156.6deg,
  #ffffff 5.3%,
  #e9f7ff 37.81%,
  #fff0f9 94.74%
);
$bg-light-toggle-menu: linear-gradient(
  179.87deg,
  #ffffff 2.5%,
  #e9f7ff 64.1%,
  #fff0f9 116.54%
);
$bg-left-menu: linear-gradient(
  200deg,
  #ffffff 1.5%,
  #e9f7ff 70.1%,
  #fff0f9 116.54%
);

$primary-color: #5433b7;
$secondary-color: #fec4b4;
$child-color-1: #5e3ebb;
$child-color-2: #7467ba;
$child-color-3: #4f467f;

$main-bg: #4f467f;

$primary-color-100: #dfe5f9;
$primary-color-200: #ede8fe;

$primary-color-opacity: rgba(0, 129, 205, 0.1);
$secondary-color-opacity: rgba(236, 0, 140, 0.1);
$child-color-1-opacity: rgba(28, 131, 172, 0.1);
$child-color-2-opacity: rgba(116, 103, 186, 0.1);
$child-color-3-opacity: rgba(79, 70, 127, 0.1);

$primary-color-opacity: rgba(0, 128, 199, 0.1);
$child-color-1-opacity-10: rgba(28, 131, 172, 0.1);
$hover-stock: #eef5f9;

$primary-color-1: #fec4b4;
$primary-color-opacity50: rgba(254, 196, 180, 0.4);

$second-color-1: #fd8061;
$second-color-1-opacity50: rgba(253, 128, 97, 0.32);

$child-color-2: #6faa4f;
$child-color-2-opacity10: rgba(111, 170, 79, 0.1);
$child-color-2-opacity50: rgba(111, 170, 79, 0.12);
$child-color-2-opacity100: rgba(111, 170, 79, 0.3);

$child-color-8-50: rgba(124, 95, 43, 0.27);

$child-color-3: #6e9d4b;

$child-color-4: #5a3584;
$child-color-4-opacity50: rgba(90, 53, 132, 0.32);
$child-color-5: #6e9d4b;
$child-color-6: #d3633d;
$child-color-7: #c78757;
$child-color-8: #7c5f2b;
$child-color-9: #e5a133;
$child-child-color-10: #3b4f94;
$child-child-color-11: #2e396d;

$font-primary-color: #131c3a;
$font-second-color: #667092;
$font-child-color-1: #667092;
$font-child-color-2: #667092;
$font-child-color-3: #6faa4f;
$font-child-color-4: #6e9d4b;
$font-child-color-5: #5a3584;
$font-child-color-6: #6e9d4b;
$font-child-color-7: #d3633d;
$font-child-color-8: #c78757;
$font-child-color-9: #7c5f2b;
$font-child-color-10: #e5a133;
$font-child-color-11: #3b4f94;
$font-child-color-12: #2e396d;
$second-font: #667092;

$modal-bg: rgba(1, 1, 6, 0.8);

$font-primary-color: #131c3a;
$font-secondary-color: #667092;
$font-child-color1: #667092;
$font-child-color2: #c9cddb;
$font-child-color3: #617581;

$gray-100: #f0f0f0;
$gray-200: #efefef;
$gray-300: #cacedb;
$gray-400: #f2f2f2;
$gray-500: #b5b8c2;
$gray-600: #afafb9;
$gray-700: #9a9aa9;
$gray-800: #818181;
$gray-900: #2a2a2a;

$icon-default: $font-child-color3;
$menu-toggle-boarder: #ebf6ff;

$white: #ffffff;
$icon-white: #3d4957;
$active-icon-white: #ffffff;
$text-form-bg: #ffffff;

$stock: #eeedf1;

$primary-font: "gothambook", sans-serif;
$font-light: "gothamlight", sans-serif;
$font-regular: "gothambook", sans-serif;
$font-medium: "gothammedium", sans-serif;
$font-bold: "gothambold", sans-serif;
$font-bold: "gothamblack", sans-serif;

$gotham300: "gothamlight", sans-serif;
$gotham400: "gothambook", sans-serif;
$gotham500: "gothambold", sans-serif;
$gotham900: "gothamblack", sans-serif;

$time-line-default: #e7e9ea;

$register-card-hover: #e5f2fa;
$hover-bg: #f7f8fa;
$hover-stock: #c9cddb;
$disabled: #c9cddb;

//----- space system
$space4: 4px;
$space6: 6px;
$space8: 8px;
$space12: 12px;
$space16: 16px;
$space18: 18px;
$space22: 22px;
$space24: 24px;
$space32: 32px;
$space42: 42px;
$space64: 64px;

//----- boarder radius
$border-radius10: 10px;
$border-radius15: 15px;
$border-radius20: 20px;

$boarder-line: #f0f0f0;
$boarder-form-2: #d1dee4;
$boarder-form-3: #f3f5f6;
$boarder-div: #f1f7fa;
$boarder-inner: #ebedf1;
$header-boarder: #ebedf1;
$boarder-light: #d6d7d8;

//system color
$error: #f85558;

//font size
$font-size-x-sm: 10px;
$font-size-sm: 12px;
$font-size-x-md: 14px;
$font-size-md: 16px;
$font-size-x-lg: 18px;
$font-size-lg: 24px;
$font-size-xx-lg: 28px;

//animation
$ease: cubic-bezier(0.2, 1, 0.3, 1);

$left-menu-width: 240px;
$right-menu-width: 400px;
