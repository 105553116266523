@import "../../assets/stylesheets/modules/variable.scss";
@import "../../assets/font/font-face.scss";
@import "../../assets/stylesheets/modules/mixin.scss";

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}

iframe {
  display: none !important;
  z-index: 0 !important;
}

body {
  position: relative;
}

.coo__form-header {
  @include font-bold();
  font-size: 24px;
}

//MODAL
.coo__modal-main-wrapper {
  position: fixed;
  background: $modal-bg;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &.show-me {
    display: block;
  }

  &.hide-me {
    display: none !important;
  }

  .coo_modal-body-content {
    background: $white;
    min-height: 300px;
    position: relative;
    margin: 1rem auto 1rem auto;
    border-radius: 15px;
    overflow: hidden;

    &.fix-400 {
      width: 400px;
    }

    &.fix-500 {
      width: 500px;
    }
    &.fix-552 {
      width: 552px;
    }
    &.fix-536 {
      width: 536px;
      border-radius: 32px;
      // height: 450px;
    }
    &.fix-906 {
      width: 906px;
    }
    &.full-height {
      height: 100%;
      width: 1165px;
      border-radius: 0;
      position: absolute;
      margin: 0;
      right: 0;
    }
  }
}

h1 {
  @include font-bold();
  font-size: 18px;
}

h3 {
  @include font-bold();
  font-size: 14px;
}

.image-crop-area-wrapper {
  background: $white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 12px 0 12px !important;
  //border: 1px solid var(--boarder-form-3);
  //border-radius: 10px;
  //transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection-item {
  margin-top: 0 !important;
}

.f-width {
  width: 100%;
}
