@import "../../../assets/stylesheets/modules/variable";
@import "../../../assets/stylesheets/modules/mixin";

.empty-data-wrapper {
  height: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .edw__img-wrapper {
    width: 110px;
    height: 110px;
    border-radius: 25px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .edw__title {
    font-size: 24px;
    @include font-regular();
    color: $font-second-color;
  }
  .edw__subtitle {
    font-size: 16px;
    @include font-light();
    color: $font-second-color;
  }
  .edw__button {
    gap: 12px;
  }
}
